import { MediaToggleButton } from '@/components/molecules/mediaToggleButton/MediaToggleButton'
import { MicToggleButton } from '@/components/molecules/micToggleButton/MicToggleButton'
import { VideoToggleButton } from '@/components/molecules/videoToggleButton/VideoToggleButton'
import { useDevices } from '@/utils/streaming/useDevices'
import { useLocalParticipant } from '@/utils/streaming/useLocalParticipant'
import { defineComponent } from 'vue'

export const MediaDevicesControls = defineComponent({
  name: 'MediaDevicesControls',
  props: {
    roomName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      micsDevices,
      camsDevices,
      updateAudioDevice,
      updateVideoDevice,
      settings,
    } = useDevices(props.roomName)
    const { local, toggleAudio, toggleVideo } = useLocalParticipant(
      props.roomName,
    )

    return () => (
      <>
        <MediaToggleButton
          onClick={toggleVideo}
          devices={camsDevices.value}
          onSelectOption={updateVideoDevice}
          curSelectedId={settings.value.videoDeviceId}
        >
          <VideoToggleButton isOn={local.value?.video} />
        </MediaToggleButton>
        <MediaToggleButton
          onClick={toggleAudio}
          devices={micsDevices.value}
          onSelectOption={updateAudioDevice}
          curSelectedId={settings.value.audioDeviceId}
        >
          <MicToggleButton isOn={local.value?.audio} />
        </MediaToggleButton>
      </>
    )
  },
})
