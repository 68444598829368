import { defineComponent } from 'vue'
import { Fade } from '@/components/atoms/fade/Fade'
import * as styles from '@/components/atoms/fade/Fade.css'

export const FadeImage = defineComponent({
  name: 'FadeImage',
  props: {
    src: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    return () =>
      props.src && (
        <Fade appear>
          <div
            class={[styles.bgImage, props.full && styles.fullImage]}
            style={{
              backgroundImage: `url(${props.src})`,
            }}
          />
        </Fade>
      )
  },
})
