import { memoize } from 'lodash'
import { useDaily } from './useDaily'
import { onceSet } from '../onceSet'
import { ref, shallowRef } from 'vue'
import {
  DailyEventObject,
  DailyEventObjectCameraError,
  DailyEventObjectNonFatalError,
} from '@daily-co/daily-js'
import { getGPUTier } from 'detect-gpu'
import * as Sentry from '@sentry/browser'

export const useErrors = memoize((roomName: string) => {
  const { callObj, removeVideoAndAudioProcessors } = useDaily(roomName)
  const cameraError = shallowRef<DailyEventObjectCameraError>()
  const nonFatalError = shallowRef<DailyEventObjectNonFatalError>()
  const isWebglUnsupported = ref<boolean>()

  const logError = (e: DailyEventObject) =>
    console.error(`useErrors: ${JSON.stringify(e)}`)

  const onCameraError = (e?: DailyEventObjectCameraError) => {
    cameraError.value = e
  }

  const onNonFatalError = (e?: DailyEventObjectNonFatalError) => {
    nonFatalError.value = e
  }

  onceSet(callObj, async (callObj) => {
    callObj
      .on('camera-error', onCameraError)
      .on('nonfatal-error', onNonFatalError)
      .on('error', logError)
      .on('load-attempt-failed', logError)

    const gpuTier = await getGPUTier({
      failIfMajorPerformanceCaveat: true,
    })

    if (gpuTier.type === 'WEBGL_UNSUPPORTED') {
      await removeVideoAndAudioProcessors()
      isWebglUnsupported.value = true
    }
    // when gpuTier.type === WEBGL_UNSUPPORTED, then gpuTier.tier is 0, but mabye they are cases
    // when the tier is 0 with WEBGL supported amd may be needed to address diffrently, for now recording only
    else if (gpuTier.tier === 0) {
      Sentry.withScope(async (scope) => {
        scope.setLevel('warning')
        console.log(`gpuTier: ${JSON.stringify(gpuTier, null, 2)}`)
        Sentry.captureMessage('GPU tier is less then 15 fps')
      })
    }
  })

  return {
    cameraError,
    nonFatalError,
    isWebglUnsupported,
  }
})
