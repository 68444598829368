import { defineComponent } from 'vue'
import micOnURL from '@/assets/MicOn.svg'
import micOffURL from '@/assets/MicOff.svg'
import { Asset } from '@/components/atoms/asset/Asset'

export const MicToggleButton = defineComponent({
  name: 'MicToggleButton',
  props: {
    isOn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => <Asset src={props.isOn ? micOnURL : micOffURL} />
  },
})
