import { defineComponent } from 'vue'
import * as styles from '@/components/atoms/svgBox/SvgBox.css'
import { uniqueId } from 'lodash'
import type { VariantPropType } from '@/utils/css'

export const SvgBox = defineComponent({
  name: 'SvgBox',
  props: {
    stopColor: {
      type: String,
      default: 'white',
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
    bgType: {
      type: String as VariantPropType<typeof styles.box, 'bgType'>,
      default: 'light',
    },
  },
  setup(props, { slots }) {
    const strokeId = uniqueId('border-grad')

    return () => {
      const { stopColor } = props

      return (
        <div
          class={[
            styles.box({ bgType: props.bgType }),
            props.isSmall ? styles.smallRoot : styles.largeRoot,
          ]}
        >
          <svg
            class={styles.svg}
            width="100%"
            height="100%"
            overflow="visible"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              rx={props.isSmall ? '15' : '23'}
              stroke={`url(#${strokeId})`}
              stroke-width="2"
            />
            <defs>
              <radialGradient
                id={strokeId}
                fx="50%"
                r="150%"
                fy="50%"
                cx="50%"
                cy="50%"
              >
                <stop stop-color={stopColor} />
                <stop offset="35%" stop-color={stopColor} stop-opacity="50%" />
                <stop offset="45%" stop-color={stopColor} stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <div class={styles.content}>{slots.default?.()}</div>
        </div>
      )
    }
  },
})
