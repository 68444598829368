import { SignJWT } from 'jose'
import { useThrottleFn } from '@vueuse/core'
import type { DailyEventObject } from '@daily-co/daily-js'
import { v4 as uuid } from 'uuid'
import { differenceWith } from 'lodash'
import { Participant } from '@/utils/streaming/useDaily'

export const signHost = async (roomName: string) => {
  const DOMAIN_ID = import.meta.env.VITE_DAILY_DOMAIN_ID as string
  const API_KEY = import.meta.env.VITE_DAILY_TOKEN as string

  // more info: https://www.daily.co/blog/announcing-self-signed-meeting-tokens/
  const payload = {
    r: roomName,
    iat: Date.now() / 1000, // the time token was issued (Epoch time)
    d: DOMAIN_ID,
    o: true, // owner
  }

  const signJwt = new SignJWT(payload)
  signJwt.setProtectedHeader({ alg: 'HS256' })

  const textEncoder = new TextEncoder()
  const keyToSignWith = textEncoder.encode(API_KEY)

  return signJwt.sign(keyToSignWith)
}

/**
 * Logic of concated userName with userId is needed because daily are changing session userId
 * for every refresh of page and can't be init with a userId like we do with pusherId
 * so this is the solution for attaching daily session id to pusher user id
 */
export const separator = '%_%'

export const toConcatedName = (userName: string, userId: string) =>
  `${userName}${separator}${userId}`

export const fromConcatedName = (concatedName: string) => {
  const [userName, userId] = concatedName.split(separator)
  // userId can be missing only in case of creating fake participants with daily api ie: "fake-participant-1".
  // to avoid break stuff and to enable testing if needed userId has uuid default
  return { userName, userId: userId ?? uuid() }
}

export const wrapThrottle = (fn: (e?: DailyEventObject) => void) =>
  useThrottleFn(fn, 250)

export const getParticipantsOrderedByTopIds = <T extends Participant>(
  participants: T[],
  topIds: string[],
  { sortRestByVideoOn = false }: { sortRestByVideoOn: boolean } = {
    sortRestByVideoOn: false,
  },
) => {
  const top = topIds.flatMap(
    (userId) => participants.find(({ user_id }) => userId === user_id) ?? [],
  )
  const rest = differenceWith(
    participants,
    top,
    (a, b) => a.user_id === b.user_id,
  )

  return [
    ...top,
    ...(sortRestByVideoOn
      ? rest.sort((a, b) => Number(b.video) - Number(a.video))
      : rest),
  ]
}
