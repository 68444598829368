import { computed } from 'vue'
import { memoize } from 'lodash'
import { useGame, useGamesCache } from '@/utils/useGame'

export const usePlayer = memoize((gameId: string) => {
  const yourGames = useGamesCache()
  const playerId = computed({
    get() {
      return yourGames.value[gameId]
    },
    set(id?: string) {
      yourGames.value = { ...yourGames.value, [gameId]: id }
    },
  })

  const { players, game, hasTeams, getPlayerTeamById } = useGame(gameId)

  const player = computed(() => {
    if (!players.value) return
    return players.value.find((player) => player.id === playerId.value) ?? null
  })

  const playerTeam = computed(() => {
    if (!playerId.value || !game.value || !hasTeams.value) return
    return getPlayerTeamById(playerId.value)
  })

  const setPlayerId = (id?: string) => {
    playerId.value = id
  }

  return {
    player,
    playerId,
    setPlayerId,
    playerTeam,
  }
})
