import * as Sentry from '@sentry/browser'
import { useLocalStorage } from '@vueuse/core'
import { once } from 'lodash'
import Pusher from 'pusher-js'
import { v4 as uuid } from 'uuid'
import { setSocketId } from '@/utils/api'

export enum ConnectionState {
  initialized = 'initialized',
  connected = 'connected',
  connecting = 'connecting',
  unavailable = 'unavailable',
  failed = 'failed',
  disconnected = 'disconnected',
}

export interface PusherSubscriptionError {
  err: string
  type: string
  status?: number
}

const LS_KEY = 'CONFETTI_GAMES_USER_ID'

export const usePusher = once(() => {
  const userId = useLocalStorage(LS_KEY, '')
  if (!userId.value) userId.value = uuid()

  const pusher = new Pusher('f3b35530d5c9f23cb58d', {
    cluster: 'us2',
    authEndpoint: '/api/pusher/auth',
    auth: {
      headers: null,
      params: {
        id: userId.value,
      },
    },
  })

  pusher.connection.bind('connected', () => {
    setSocketId(pusher.connection.socket_id)
  })

  pusher.bind(
    'pusher:error',
    ({ code, message }: { code: number | null; message: string }) => {
      const err = new Error(message)
      Sentry.captureException(Object.assign(err, { statusCode: code }))
      console.error(err)
    },
  )

  const disconnect = () => pusher.disconnect()

  return { pusher, userId, disconnect }
})
