import { nextTick, watch, WatchSource } from 'vue'
import { tryOnUnmounted } from '@vueuse/core'

export const onceSet = <T>(
  source: WatchSource<T | false | null | undefined>,
  callback: (value: T) => void,
  { once = true } = {},
) => {
  const unwatch = watch(
    source,
    async (value) => {
      if (!value) return
      await nextTick()
      if (once) unwatch()
      callback(value)
    },
    { immediate: true },
  )

  tryOnUnmounted(unwatch)
}
