import { defineComponent } from 'vue'
import videoOnURL from '@/assets/VideoOn.svg'
import videoOffURL from '@/assets/VideoOff.svg'
import { Asset } from '@/components/atoms/asset/Asset'

export const VideoToggleButton = defineComponent({
  name: 'VideoToggleButton',
  props: {
    isOn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => <Asset src={props.isOn ? videoOnURL : videoOffURL} />
  },
})
