import { defineComponent, Teleport } from 'vue'
import * as styles from '@/components/atoms/modal/Modal.css'
import { OnClickOutside } from '@vueuse/components'
import {
  Backdrop,
  BackdropPropType,
} from '@/components/atoms/backdrop/Backdrop'
import { Fade } from '@/components/atoms/fade/Fade'
import { onKeyUp } from '@vueuse/core'
import type { VariantPropType } from '@/utils/css'
import { FadeFromBottom } from '@/components/atoms/fade/FadeFromBottom'
import { IconButton } from '../iconButton/IconButton'
import { Asset } from '../asset/Asset'
import closeURL from '@/assets/Close.svg'

export type ContentPositionPropType = VariantPropType<
  typeof styles.content,
  'position'
>

export const Modal = defineComponent({
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    backdropType: {
      type: String as BackdropPropType,
      default: 'dark',
    },
    contentPosition: {
      type: String as ContentPositionPropType,
      default: 'center',
    },
    useTransition: {
      type: Boolean,
      default: true,
    },
    pointerEvents: {
      type: String as VariantPropType<typeof styles.content, 'pointerEvents'>,
      default: 'all',
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  setup(props, { slots, emit }) {
    const close = () => emit('close')
    onKeyUp('Escape', close)

    return () => (
      <Teleport to="body">
        {props.show && (
          <div
            class={[
              styles.root,
              props.visible ? styles.modalRootVisible : styles.modalRootHidden,
            ]}
          >
            {props.visible && (
              <Fade appear>
                <Backdrop class={styles.backdrop} type={props.backdropType} />
              </Fade>
            )}
            {props.showCloseButton && (
              <IconButton class={styles.closeButton}>
                <Asset src={closeURL} />
              </IconButton>
            )}
            <OnClickOutside
              // @ts-ignore
              onTrigger={close}
              class={styles.content({
                position: props.contentPosition,
                pointerEvents: props.pointerEvents,
              })}
            >
              <FadeFromBottom appear css={props.useTransition}>
                {slots.default?.()}
              </FadeFromBottom>
            </OnClickOutside>
          </div>
        )}
      </Teleport>
    )
  },
})
