import { computed, ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { isEqual, memoize, sortBy } from 'lodash'
import { getGame, getGamePrologue, getGameEpilogue } from '@/utils/api'
import { Game, GamePlayer } from '../../types'
import { getImageUrl } from '@/utils/urls'
import { useChannel } from './useChannel'
import { useChannelState } from './useChannelState'

export const LS_KEY = 'CONFETTI_GAMES'
export const useGamesCache = () =>
  useLocalStorage<Record<string, string | undefined>>(LS_KEY, {})

const getGameMemoized = memoize(
  (code, _revisionId) => getGame(code),
  (_, revisionId) => revisionId,
)

export const CHANNEL_EVENTS = {
  GO_TO_GAME: 'client-go-to-team',
  GO_TO_LOBBY: 'client-go-to-lobby',
  GO_TO_FIRST_CHAPTER: 'client-go-to-first-chapter',
}

export const useGame = memoize((gameId: string) => {
  const game = ref<Game>()

  const updateGame = () => getGame(gameId).then(setGame)
  const { channel } = useChannel(`presence-game-${gameId}`)
  const { channelMembers: gameChannelMembers, unavailable } = useChannelState(
    channel,
    updateGame,
  )

  const players = computed(() => {
    const ids = Object.keys(gameChannelMembers.value)
    if (!ids.length || !game.value) return

    const results = [] as GamePlayer[]

    for (const key in game.value.players) {
      const i = Number(key)
      const player = game.value.players[i]
      const isActive = ids.includes(player.pusherId)
      if (!isActive) continue
      results.push(player)
    }

    return sortBy(results, [
      ({ pusherId }) => gameChannelMembers.value[pusherId].ts,
    ])
  })

  const setGame = async (value: Game) => {
    if (!game.value) {
      game.value = value
      return
    }

    // game not updated
    if (
      value._rev === game.value._rev ||
      value._updatedAt < game.value._updatedAt
    ) {
      // even tho game is not updated (_rev, _updatedAt), maybe teams is updated
      if (!isEqual(game.value, value)) {
        game.value = value
      }
      return
    }

    // game revision changed but updatedAt could be the same (issue with Sanity)
    game.value =
      value._updatedAt > game.value._updatedAt
        ? value
        : await getGameMemoized(gameId, value._rev)
  }

  const getEpilogue = async () => {
    return await getGameEpilogue(gameId)
  }

  const getPrologue = async () => {
    return await getGamePrologue(gameId)
  }

  const introCover = computed(() => {
    if (!game.value) return
    return getImageUrl(game.value.introCover).width(2000).url()
  })

  const teamCover = computed(() => {
    if (!game.value) return
    return getImageUrl(game.value.teamCover).width(2000).url()
  })

  const playerCover = computed(() => {
    if (!game.value) return
    return getImageUrl(game.value.playerCover).width(720).height(405).url()
  })

  const hostCover = computed(() => {
    if (!game.value) return
    return getImageUrl(game.value.hostCover).width(720).height(405).url()
  })

  const hasTeams = computed(() => Boolean(game.value?.teams.length))

  const getPlayerTeamById = (playerId: string) => {
    if (!game.value) return
    return game.value.teams.find(({ players }) =>
      players.some(({ id }) => id === playerId),
    )
  }

  const playersWithoutHost = computed(
    () => players.value?.filter((p) => !p.isHost) ?? [],
  )

  return {
    players,
    setGame,
    gameChannelMembers,
    game,
    getEpilogue,
    getPrologue,
    introCover,
    teamCover,
    playerCover,
    hostCover,
    hasTeams,
    getPlayerTeamById,
    unavailable,
    channel,
    playersWithoutHost,
  }
})
