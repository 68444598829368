import {
  DailyEventObjectActiveSpeakerChange,
  DailyTrackState,
} from '@daily-co/daily-js'
import { ref, computed } from 'vue'
import { memoize, omit, pick } from 'lodash'
import { fromConcatedName, wrapThrottle } from './helpers'
import { useDaily, Participant, UserData } from './useDaily'
import { onceSet } from '../onceSet'

const omitTrack = (trackState: DailyTrackState) => omit(trackState, ['track'])

export const useParticipants = memoize((roomName: string) => {
  const { callObj } = useDaily(roomName)
  const participantsAndLocal = ref<Participant[]>([])
  const activeSpeaker = ref<string>()

  const updateParticipants = () => {
    if (!callObj.value) return
    participantsAndLocal.value = Object.values(
      callObj.value.participants(),
    ).map((p) => ({
      tracks: {
        audio: omitTrack(p.tracks.audio),
        video: omitTrack(p.tracks.video),
        screenAudio: omitTrack(p.tracks.screenAudio),
        screenVideo: omitTrack(p.tracks.screenVideo),
      },
      userData: p.userData as UserData,
      ...pick(p, [
        'session_id',
        'user_id',
        'joined_at',
        'user_name',
        'local',
        'audio',
        'video',
        'screen',
        'owner',
      ]),
      _game: fromConcatedName(p.user_name),
    }))
  }

  const throttledUpdateParticipants = wrapThrottle(updateParticipants)

  const onActiveSpeakerChange = (e?: DailyEventObjectActiveSpeakerChange) => {
    activeSpeaker.value = e?.activeSpeaker.peerId
  }

  // no need to off events, being handle in useLocalParticipant leaveCall
  onceSet(callObj, (callObj) => {
    callObj
      .on('joined-meeting', updateParticipants) // after local joined, we want to make sure participants are updated
      .on('participant-joined', throttledUpdateParticipants)
      .on('participant-updated', throttledUpdateParticipants)
      .on('participant-left', throttledUpdateParticipants)
      .on('track-started', throttledUpdateParticipants)
      .on('track-stopped', throttledUpdateParticipants)
      .on('active-speaker-change', onActiveSpeakerChange)

    updateParticipants()
  })

  const participants = computed(() =>
    participantsAndLocal.value.filter((p) => !p.local),
  )

  return {
    participantsAndLocal,
    participants,
    activeSpeaker,
  }
})
