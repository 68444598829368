import {
  defineComponent,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
  PropType,
} from 'vue'

export const AudioSource = defineComponent({
  name: 'AudioSource',
  props: {
    getAudioSource: {
      type: Function as PropType<() => MediaStream | null>,
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance()

    onMounted(() => {
      watch(
        props.getAudioSource,
        (audioSource) => {
          nextTick(() => {
            const audio = instance?.refs.audio as HTMLAudioElement | undefined
            if (audio) {
              audio.srcObject = audioSource
            }
          })
        },
        { immediate: true },
      )
    })

    return () => (
      <audio autoplay playsinline ref="audio">
        <track kind="captions" />
      </audio>
    )
  },
})
