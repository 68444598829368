import { useStorage } from '@vueuse/core'
import { once } from 'lodash'

const LS_KEY = 'CONFETTI_GAMES_DAILY_SETTINGS'

export const useStorageSettings = once(() =>
  useStorage<{
    video: boolean | undefined
    audio: boolean | undefined
    videoDeviceId: string | undefined
    audioDeviceId: string | undefined
  }>(LS_KEY, {
    video: undefined,
    audio: undefined,
    videoDeviceId: undefined,
    audioDeviceId: undefined,
  }),
)
