import { defineComponent } from 'vue'
import * as styles from '@/components/molecules/confettiLogo/ConfettiLogo.css'
import confettiLogoURL from '@/assets/Logo.svg'
import { Asset } from '@/components/atoms/asset/Asset'
import { SvgBox } from '@/components/atoms/svgBox/SvgBox'

export const ConfettiLogo = defineComponent({
  name: 'ConfettiLogo',
  setup() {
    return () => (
      <SvgBox class={styles.svg} bgType="dark">
        <div class={styles.root}>
          <Asset
            class={[styles.logo, styles.upperLogo]}
            src={confettiLogoURL}
            alt="Confetti Logo"
            invert={true}
          />
          <Asset
            class={[styles.logo, styles.glow]}
            src={confettiLogoURL}
            alt=""
          />
        </div>
      </SvgBox>
    )
  },
})
