import { defineComponent, ref } from 'vue'
import * as styles from '@/components/molecules/confirmModal/ConfirmModal.css'
import { GameButton } from '@/components/atoms/gameButton/GameButton'
import { Modal } from '@/components/atoms/modal/Modal'
import { rawRgb } from '@/styles/colors.css'

export const ConfirmModal = defineComponent({
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    confirmText: {
      type: String,
      default: 'Got It',
    },
  },
  emits: ['confirm'],
  setup(props, { slots, emit }) {
    const showModal = ref(true)

    const onConfirm = () => {
      showModal.value = false
      emit('confirm')
    }

    return () => (
      <Modal show={showModal.value} showCloseButton={false}>
        <div class={styles.root}>
          <div class={styles.title}>{props.title}</div>
          <div class={styles.content}>{slots.content?.()}</div>
          <GameButton
            text={props.confirmText}
            rgbColor={rawRgb.white}
            textColor="black"
            size="medium"
            minWidth="70px"
            onClick={onConfirm}
          />
        </div>
      </Modal>
    )
  },
})
