import {
  computed,
  reactive,
  ref,
  shallowRef,
  unref,
  watch,
  watchEffect,
} from 'vue'
import { memoize, slice, maxBy, uniq, compact, isEmpty } from 'lodash'
import { useDaily, Participant } from '@/utils/streaming/useDaily'
import { useParticipants } from '@/utils/streaming/useParticipants'
import { useMediaQuery, useNow } from '@vueuse/core'
import { useLocalParticipant } from './useLocalParticipant'
import { getParticipantsOrderedByTopIds } from '@/utils/streaming/helpers'

type VisiableParticipant = Participant | null

type Pagination = {
  start: number
  end: number
}

export const getPaginationIds = ({
  pagination,
  pageSize,
  participants,
}: {
  pagination: Pagination
  pageSize: number
  participants: Participant[]
}) => {
  const start = pagination.start
  const end = pagination.end || pageSize
  const subscribedIds = slice(participants, start, end).map(
    ({ user_id }) => user_id,
  )
  const prev = start > 0 ? slice(participants, start - pageSize, start) : []
  const next =
    end !== participants.length ? slice(participants, end, end + pageSize) : []
  const stagedIds = [...prev, ...next].map(({ user_id }) => user_id)
  return { subscribedIds, stagedIds }
}

export const usePaginatedParticipants = memoize((roomName: string) => {
  const { callObj } = useDaily(roomName)
  const { participants, activeSpeaker } = useParticipants(roomName)
  const { local, setHighQualityUserId } = useLocalParticipant(roomName)
  const pagination = reactive<Pagination>({ start: 0, end: 0 })
  const isMeduimScreen = useMediaQuery('(min-width: 1120px)')
  const isLargeScreen = useMediaQuery('(min-width: 1441px)')
  const pinnedUserId = ref<string>()
  const visibleParticipants = ref<VisiableParticipant[]>([])
  const activeSpeakersLastActiveTime = ref<Record<string, number>>({})
  const activeSpeakersIds = shallowRef<string[]>([])

  const pageSize = computed<number>(() =>
    isLargeScreen.value ? 12 : isMeduimScreen.value ? 11 : 4,
  )

  const host = computed(() => {
    const ownerList = participants.value.filter(({ owner }) => owner)
    if (local.value?.owner) {
      ownerList.push(local.value)
    }
    return maxBy(ownerList, ({ joined_at }) => joined_at)
  })

  const updateParticipantsSubscriptions = ({
    participants,
    subscribedIds,
    stagedIds,
  }: {
    participants: Participant[]
    subscribedIds: string[]
    stagedIds: string[]
  }) => {
    const updates = participants.reduce(
      (acc, { session_id, user_id: id, tracks }) => {
        let desiredVideoSubscription
        let desiredScreenVideoSubscription
        let desiredScreenAudioSubscription

        const curVideoSubscription = tracks?.video?.subscribed
        const curScreenVideoSubscription = tracks?.screenVideo?.subscribed
        const curScreenAudioSubscription = tracks?.screenAudio?.subscribed

        if (subscribedIds.includes(id)) {
          desiredVideoSubscription = true
          const isStageParticipant = stageParticipant.value?.user_id === id
          desiredScreenVideoSubscription = isStageParticipant
          desiredScreenAudioSubscription = isStageParticipant
        } else if (stagedIds.includes(id)) {
          desiredVideoSubscription = 'staged'
          desiredScreenVideoSubscription = false
          desiredScreenAudioSubscription = false
        } else {
          desiredVideoSubscription = false
          desiredScreenVideoSubscription = false
          desiredScreenAudioSubscription = false
        }

        if (
          desiredVideoSubscription === curVideoSubscription &&
          desiredScreenVideoSubscription === curScreenVideoSubscription &&
          desiredScreenAudioSubscription === curScreenAudioSubscription
        )
          return acc

        return {
          ...acc,
          [session_id]: {
            setSubscribedTracks: {
              video: desiredVideoSubscription,
              screenVideo: desiredScreenVideoSubscription,
              screenAudio: desiredScreenAudioSubscription,
              audio: true,
            },
          },
        }
      },
      {},
    )

    if (!isEmpty(updates)) {
      callObj.value?.updateParticipants(updates)
    }
  }

  const stageParticipant = computed(() => {
    const pinned = unref(pinnedUserId)
    const curLocal = unref(local)
    const curParticipants = unref(participants)

    if (pinned) {
      if (pinned === curLocal?._game.userId) return curLocal
      else {
        const pinnedUser = curParticipants.find(
          ({ _game }) => _game.userId === pinned,
        )

        if (pinnedUser) return pinnedUser
      }
    }

    const hostUserId = host.value?._game.userId

    if (hostUserId) {
      if (curLocal?._game.userId === hostUserId) return curLocal
      else
        return curParticipants.find(({ _game }) => _game.userId === hostUserId)
    }

    return undefined
  })

  const now = useNow({ interval: 30 * 1000 })
  const visibleActiveSpeakersIds = computed(() => {
    const currentTime = now.value.getTime()
    return activeSpeakersIds.value.filter((activeSpeakerId) => {
      if (activeSpeakerId === activeSpeaker.value) return true
      const lastActive = activeSpeakersLastActiveTime.value[activeSpeakerId]
      return lastActive && lastActive + 1000 * 60 > currentTime
    })
  })

  const gridParticipants = computed(() => {
    const curParticipantsWithoutHost = participants.value.filter(
      ({ user_id }) => user_id !== host.value?.user_id,
    )
    const curStageParticipant = unref(stageParticipant)
    const pinned = unref(pinnedUserId)
    const curHost = unref(host)
    const participantsWithMe = compact([...participants.value, local.value])
    const pinnedParticipant = participantsWithMe.find(
      ({ _game }) => pinned === _game.userId,
    )

    const gridParticipants = getParticipantsOrderedByTopIds(
      curStageParticipant && pinnedParticipant
        ? compact(
            curParticipantsWithoutHost.map((participant) => {
              if (pinnedParticipant._game.userId === participant._game.userId) {
                if (
                  curHost &&
                  curHost._game.userId !== local.value?._game.userId
                ) {
                  return curHost
                }
                return undefined
              }

              return participant
            }),
          )
        : curParticipantsWithoutHost,
      visibleActiveSpeakersIds.value,
      { sortRestByVideoOn: true },
    )

    // if the local is the pinned user, nothing to swap, just add to start of tiles
    if (
      host.value &&
      local.value?._game.userId === pinnedParticipant?._game.userId
    ) {
      gridParticipants.unshift(host.value)
    }

    return gridParticipants
  })

  watchEffect(() => {
    const gridParticipantsOrEmptyArray = gridParticipants.value ?? []
    const { subscribedIds, stagedIds } = getPaginationIds({
      pagination,
      pageSize: pageSize.value,
      participants: gridParticipantsOrEmptyArray,
    })

    updateParticipantsSubscriptions({
      participants: participants.value,
      subscribedIds: stageParticipant.value
        ? uniq([...subscribedIds, stageParticipant.value.user_id])
        : subscribedIds,
      stagedIds,
    })

    visibleParticipants.value = gridParticipantsOrEmptyArray.filter(
      ({ user_id }) => subscribedIds.includes(user_id),
    )

    if (pageSize.value > visibleParticipants.value.length) {
      const placeholderCount = pageSize.value - visibleParticipants.value.length
      visibleParticipants.value.push(...new Array(placeholderCount).fill(null))
    }
  })

  watch(stageParticipant, (participant) =>
    setHighQualityUserId(participant?.user_id),
  )

  watch(activeSpeaker, (activeSpeaker, lastActiveSpeaker) => {
    const now = new Date().getTime()

    if (lastActiveSpeaker) {
      activeSpeakersLastActiveTime.value[lastActiveSpeaker] = now
    }

    if (!activeSpeaker) return

    const isSpeakerVisible = visibleParticipants.value.some(
      (participant) => participant?.user_id === activeSpeaker,
    )

    if (isSpeakerVisible) return

    activeSpeakersIds.value = uniq([activeSpeaker, ...activeSpeakersIds.value])
  })

  const participantsCount = computed(
    () =>
      participants.value.filter(
        ({ user_id }) => stageParticipant.value?.user_id !== user_id,
      ).length,
  )

  return {
    visibleParticipants,
    pagination,
    pageSize,
    isMeduimScreen,
    isLargeScreen,
    pinnedUserId,
    stageParticipant,
    participantsCount,
    host,
  }
})
