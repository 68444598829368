import { defineComponent, PropType } from 'vue'
import * as styles from '@/components/atoms/toggleButtonSelectOptions/ToggleButtonSelectOptions.css'
import bottomArrowwURL from '@/assets/BottomArrow.svg'

export const ToggleButtonSelectOptions = defineComponent({
  name: 'ToggleButtonSelectOptions',
  props: {
    onSelectOption: {
      type: Function as PropType<(value: string) => void>,
      default: null,
    },
  },
  setup(props, { slots }) {
    return () => {
      const selectOptions = slots.selectOptions?.()

      const onSelectOption = (e: Event) => {
        props.onSelectOption?.((e.target as HTMLSelectElement).value)
      }

      return (
        <div class={[styles.root, selectOptions && styles.rootWithSelect]}>
          <div class={[styles.outerButton, styles.outerButtonLeftButton]}>
            <div
              class={[
                styles.innerButton,
                selectOptions
                  ? styles.innerButtonWithSelectLeft
                  : styles.innerButtonWithoutSelect,
              ]}
            >
              <div class={styles.icon}>{slots.icon?.()}</div>
            </div>
          </div>
          {selectOptions && (
            <div class={[styles.outerButton, styles.outerButtonRightButton]}>
              <div
                class={[styles.innerButton, styles.innerButtonWithSelectRight]}
              >
                <select
                  class={styles.select}
                  onChange={onSelectOption}
                  style={{
                    backgroundImage: `url(${bottomArrowwURL})`,
                  }}
                >
                  {selectOptions}
                </select>
              </div>
            </div>
          )}
        </div>
      )
    }
  },
})
