import { defineComponent, withModifiers } from 'vue'
import * as styles from '@/components/atoms/iconButton/IconButton.css'
import type { VariantPropType } from '@/utils/css'

export const IconButton = defineComponent({
  name: 'IconButton',
  props: {
    type: {
      type: String as VariantPropType<typeof styles.iconButton, 'type'>,
      default: 'white',
    },
  },
  emits: ['click'],
  setup(props, { slots, emit }) {
    return () => (
      <button
        class={styles.iconButton({ type: props.type })}
        onClick={withModifiers(() => emit('click'), ['stop'])}
      >
        {slots.default?.()}
      </button>
    )
  },
})
