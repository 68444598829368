import { defineComponent } from 'vue'
import * as styles from '@/components/atoms/playerPlaceholder/PlayerPlaceholder.css'
import placeholderURL from '@/assets/Placeholder.svg'
import { Asset } from '@/components/atoms/asset/Asset'

export const PlayerPlaceholder = defineComponent({
  name: 'PlayerPlaceholder',
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <div
        class={[
          styles.root,
          props.isLarge ? styles.rootLargeBorder : styles.rootSmallBorder,
          !props.noBackground && styles.rootBackground,
        ]}
      >
        <Asset
          class={[
            styles.placeholder,
            props.isLarge ? styles.placeholderLarge : styles.placeholderSmall,
          ]}
          src={placeholderURL}
        />
      </div>
    )
  },
})
