import DailyIframe, {
  DailyCall,
  DailyParticipant,
  DailyCallOptions,
  DailyTrackState,
} from '@daily-co/daily-js'
import { memoize } from 'lodash'
import { InjectionKey, ShallowRef, shallowRef } from 'vue'
import { onceSet } from '../onceSet'
import { useStorageSettings } from './useStorageSettings'
import * as Sentry from '@sentry/browser'

type DailyTrackStateWithoutTrack = Omit<DailyTrackState, 'track'>

export type UserData = { shareScreenAudioOnly: boolean } | undefined

export type Participant = Pick<
  DailyParticipant,
  | 'audio'
  | 'video'
  | 'screen'
  | 'session_id'
  | 'user_id'
  | 'user_name'
  | 'joined_at'
  | 'local'
  | 'owner'
> & {
  tracks: {
    audio: DailyTrackStateWithoutTrack
    video: DailyTrackStateWithoutTrack
    screenVideo: DailyTrackStateWithoutTrack
    screenAudio: DailyTrackStateWithoutTrack
  }
  _game: {
    userName: string
    userId: string
  }
  userData?: UserData
}

const BASE_ROOM_URL = import.meta.env.VITE_DAILY_URL
export const callKey: InjectionKey<ShallowRef<DailyCall>> = Symbol('call')

export const useDaily = memoize((roomName: string) => {
  const callObj = shallowRef<DailyCall>()
  const settings = useStorageSettings()

  const createDailyObjIfNotExist = ({
    subscribeToTracksAutomatically = false,
    backgroundImage,
    isLobby,
  }: Pick<DailyCallOptions, 'subscribeToTracksAutomatically'> & {
    backgroundImage: string
    isLobby: boolean
  }) => {
    if (callObj.value) return

    try {
      callObj.value = DailyIframe.createCallObject({
        url: `${BASE_ROOM_URL}/${roomName}`,
        subscribeToTracksAutomatically,
        dailyConfig: {
          keepCamIndicatorLightOn: false,
          v2CamAndMic: true,
          userMediaVideoConstraints: {
            width: 720,
            height: 405,
          },
        },
        audioSource: settings.value.audioDeviceId ?? true,
        videoSource: settings.value.videoDeviceId ?? true,
        strictMode: true,
        inputSettings: {
          video: {
            processor: {
              type: 'background-image',
              config: {
                source: backgroundImage,
              },
            },
          },
          audio: { processor: { type: 'noise-cancellation' } },
        },
        sendSettings: {
          screenVideo: isLobby
            ? 'detail-optimized'
            : 'motion-and-detail-balanced',
        },
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const removeVideoAndAudioProcessors = async () => {
    await callObj.value?.updateInputSettings({
      video: {
        processor: {
          type: 'none',
        },
      },
      audio: {
        processor: {
          type: 'none',
        },
      },
    })
  }

  onceSet(callObj, (callObj) => {
    // @ts-ignore
    window.callObj = new Proxy(callObj, {
      get(target, prop, receiver) {
        // @TODO removed gurad for now
        //  if (!window.USE_CALL_OBJ) return {}
        return Reflect.get(target, prop, receiver)
      },
    })
  })

  return {
    createDailyObjIfNotExist,
    removeVideoAndAudioProcessors,
    callObj,
  }
})
