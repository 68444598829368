import { Participant } from './useDaily'
import { useMediaStream } from './useMediaStream'

export const useVideo = (getParticipant: () => Participant) => {
  const getMediaStreamData = () => {
    const participant = getParticipant()

    return {
      persistentTrack: participant?.tracks?.video?.persistentTrack,
      shouldSetMediaStream: participant.video,
    }
  }

  return useMediaStream(getMediaStreamData)
}
