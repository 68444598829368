import { defineComponent, ref } from 'vue'
import { ConfirmModal } from '@/components/molecules/confirmModal/ConfirmModal'
import { useErrors } from '@/utils/streaming/useErrors'

export const MediaWebglUnsupportedModal = defineComponent({
  name: 'MediaWebglUnsupportedModal',
  props: {
    roomName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { isWebglUnsupported } = useErrors(props.roomName)
    const hideModal = ref<boolean>()

    const onConfirm = () => {
      hideModal.value = true
    }

    return () => {
      if (!isWebglUnsupported.value || hideModal.value) return null

      return (
        <ConfirmModal
          title="Background Image Disabled"
          v-slots={{
            content: () =>
              `Since your browser does not support the latest graphic requirements, we disabled your background image to ensure a smooth and functional experience for you.`,
          }}
          onConfirm={onConfirm}
        />
      )
    }
  },
})
