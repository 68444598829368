import { defineComponent, PropType } from 'vue'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import * as styles from '@/components/atoms/gameButton/GameButton.css'
import { fullColorsVariants, rawRgb } from '@/styles/colors.css'
import { CircleLoader } from '@/components/atoms/circleLoader/CircleLoader'
import type { VariantPropType } from '@/utils/css'

export const GameButton = defineComponent({
  name: 'GameButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    badgeText: {
      type: String,
      default: '',
    },
    rgbColor: {
      type: String,
      default: rawRgb.white,
    },
    textColor: {
      type: String as PropType<keyof typeof fullColorsVariants>,
      default: 'white',
    },
    size: {
      type: String as VariantPropType<typeof styles.button, 'size'>,
      default: 'small',
    },
    contentPosition: {
      type: String as VariantPropType<typeof styles.button, 'contentPosition'>,
      default: 'center',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fxBgImage: {
      type: String,
      default: null,
    },
    minWidth: {
      type: String as VariantPropType<typeof styles.button, 'minWidth'>,
      default: '128px',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    return () => (
      <button
        class={styles.button({
          size: props.size,
          contentPosition: props.contentPosition,
          minWidth: props.minWidth,
        })}
        style={assignInlineVars({
          [styles.rgbColor]: props.rgbColor,
        })}
        onClick={(e) => emit('click', e)}
        disabled={props.disabled || props.loading}
      >
        {props.loading ? (
          <CircleLoader
            class={[styles.loader, fullColorsVariants[props.textColor]]}
          />
        ) : (
          <div class={styles.content}>
            <div class={[styles.text, fullColorsVariants[props.textColor]]}>
              {props.text}
            </div>
            {props.badgeText && (
              <div class={styles.badge}>
                <div class={styles.badgeText}>{props.badgeText}</div>
              </div>
            )}
          </div>
        )}
        <div
          class={styles.fx}
          style={
            props.fxBgImage
              ? {
                  backgroundImage: `url(${props.fxBgImage})`,
                  backgroundSize: '100%',
                }
              : undefined
          }
        >
          <div class={styles.whiteGlow} />
          <div class={styles.darkBlur} />
        </div>
      </button>
    )
  },
})
