import { defineComponent } from 'vue'
import * as styles from '@/components/atoms/circleLoader/CircleLoader.css'

export const CircleLoader = defineComponent({
  name: 'CircleLoader',
  setup() {
    return () => (
      <div class={styles.root}>
        <svg class={styles.svg} viewBox="0 0 1 1" />
        <div class={styles.loader}>
          <div class={styles.effect1} />
          <div class={styles.effect2} />
          <div class={styles.effect3} />
        </div>
      </div>
    )
  },
})
