import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import {
  Chapter,
  Team,
  Hint,
  GamePrologue,
  GameEpilogue,
  Game,
  Leaderboard,
} from '../../types'

const api = axios.create({
  baseURL: '/api',
})

axiosRetry(api, { retries: 3 })

export const assertStatusCode = (err: Error, statusCode: number) => {
  if ((err as AxiosError).request?.status !== statusCode) throw err
}

export const setSocketId = (socketId?: string) => {
  // @ts-ignore: https://github.com/axios/axios/issues/4193
  api.defaults.headers['x-socket-id'] = socketId
}

export const getGame = async (gameId: string) => {
  const { data } = await api.get<Game>(`game/${gameId}`)
  return data
}

export const updateGame = async (gameId: string) => {
  const { data } = await api.post<Game>(`game/${gameId}`)
  return data
}

export const getGamePrologue = async (gameId: string) => {
  const { data } = await api.get<GamePrologue>(`/game/${gameId}/prologue`)
  return data
}

export const getGameEpilogue = async (gameId: string) => {
  const { data } = await api.get<GameEpilogue>(`/game/${gameId}/epilogue`)
  return data
}

export const joinGame = async ({
  gameId,
  name,
  gamePassword,
  pusherId,
  hostCode,
}: {
  gameId: string
  name: string
  gamePassword: string
  pusherId: string
  hostCode?: string
}) => {
  const { data } = await api.post<{ playerId: string; playerKey: string }>(
    `/game/${gameId}/join`,
    {
      name,
      gamePassword,
      pusherId,
      hostCode,
    },
  )
  return data
}

export const createGameTeams = async (gameId: string, amount: number) => {
  const { data } = await api.post<Game>(`/game/${gameId}/teams`, {
    amount,
  })
  return data
}

export const createGameTeamsAndRandomizePlayers = async (
  gameId: string,
  amount: number,
) => {
  const { data } = await api.post<Game>(`/game/${gameId}/randomize`, {
    amount,
  })
  return data
}

export const unrandomize = async (gameId: string) => {
  const { data } = await api.post<Game>(`/game/${gameId}/unrandomize`)
  return data
}

export const getSmallestAvailableTeam = async (gameId: string) => {
  const { data } = await api.get<{ id: string } | null>(
    `/game/${gameId}/randomize`,
  )
  return data
}

export const startGameWithRevision = async (
  gameId: string,
  revisionId: string,
) => {
  const { data } = await api.post<Game>(`/game/${gameId}/start`, {
    revisionId,
  })
  return data
}

export const getLeaderboard = async (gameId: string) => {
  const { data } = await api.get<Leaderboard>(`/game/${gameId}/leaderboard`)
  return data
}

export const toggleShowLeaderboard = async (gameId: string) => {
  const { data } = await api.post<Game>(`/game/${gameId}/leaderboard`)
  return data
}

export const setPinnedUser = async (gameId: string, userId: string) => {
  const { data } = await api.post<Game>(`/game/${gameId}/pin`, {
    userId,
  })
  return data
}

export const getTeam = async (teamId: string) => {
  const { data } = await api.get<Team>(`/team/${teamId}`)
  return data
}

export const updateTeam = async (teamId: string) => {
  const { data } = await api.post<Team>(`/team/${teamId}`)
  return data
}

export const joinTeam = async ({
  teamId,
  playerId,
  playerKey,
  pusherId,
}: {
  teamId: string
  playerId: string
  playerKey: string
  pusherId: string
}) => {
  const { data } = await api.post<Team>(`/team/${teamId}/join`, {
    playerId,
    playerKey,
    pusherId,
  })
  return data
}

export const createTeamRoom = async (teamId: string) => {
  const { data } = await api.post<string>(`/team/${teamId}/room`)
  return data
}

export const startTeamGameWithRevision = async (
  teamId: string,
  revisionId: string,
) => {
  const { data } = await api.post<Team>(`/team/${teamId}/start`, {
    revisionId,
  })
  return data
}

export const sendChapterCode = async (
  teamId: string,
  chapter: string,
  code: string,
) => {
  const { data } = await api.post<Team>(
    `/team/${teamId}/chapter/${chapter}/solve`,
    { code },
  )
  return data
}

export const skipChapter = async (teamId: string, chapter: string) => {
  const { data } = await api.post<Team>(
    `/team/${teamId}/chapter/${chapter}/skip`,
  )
  return data
}

export const getChapterContent = async (teamId: string, chapter: string) => {
  const { data } = await api.get<Chapter>(`/team/${teamId}/chapter/${chapter}`)
  return data
}

export const getAvailableHints = async (teamId: string, chapter: string) => {
  const { data } = await api.get<Hint[]>(
    `/team/${teamId}/chapter/${chapter}/hints`,
  )
  return data
}

export const getChapterSolution = async (teamId: string, chapter: string) => {
  const { data } = await api.get<string[]>(
    `/team/${teamId}/chapter/${chapter}/solution`,
  )
  return data
}

export const unlockChapterHint = async (
  teamId: string,
  chapter: string,
  key: string,
) => {
  const { data } = await api.post<Team>(
    `/team/${teamId}/chapter/${chapter}/unlock-hint`,
    { key },
  )
  return data
}

export const setPlayerReady = async (teamId: string, playerId: string) => {
  const { data } = await api.post<Team>(
    `team/${teamId}/player/${playerId}/ready`,
  )
  return data
}

export const removePlayerFromTeam = async ({
  teamId,
  playerId,
}: {
  teamId: string
  playerId: string
}) => {
  const { data } = await api.post<string>(
    `team/${teamId}/player/${playerId}/remove`,
  )
  return data
}
