import { defineComponent, PropType } from 'vue'
import { ToggleButtonSelectOptions } from '@/components/atoms/toggleButtonSelectOptions/ToggleButtonSelectOptions'

export const MediaToggleButton = defineComponent({
  name: 'MediaToggleButton',
  props: {
    devices: {
      type: Array as PropType<MediaDeviceInfo[]>,
      default: () => [],
    },
    curSelectedId: {
      type: String,
      default: '',
    },
    onSelectOption: {
      type: Function as PropType<(value: string) => void>,
      default: null,
    },
  },
  emits: ['click'],
  setup(props, { emit, slots }) {
    return () => {
      const showSelectOptions = props.devices.length > 1

      return (
        <ToggleButtonSelectOptions
          onSelectOption={props.onSelectOption}
          v-slots={{
            icon: () => (
              <div onClick={() => emit('click')}>{slots.default?.()}</div>
            ),
            selectOptions: showSelectOptions
              ? () =>
                  props.devices.map(({ label, deviceId }) => (
                    <option
                      value={deviceId}
                      selected={props.curSelectedId === deviceId}
                    >
                      {label}
                    </option>
                  ))
              : undefined,
          }}
        />
      )
    }
  },
})
