import {
  DailyCallOptions,
  DailyCpuLoadStats,
  DailyNetworkStats,
  DailyParticipantUpdateOptions,
  DailyReceiveSettingsUpdates,
  DailyVideoSendSettings,
} from '@daily-co/daily-js'
import { computed, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'
import { signHost, toConcatedName } from './helpers'
import { Participant, useDaily } from './useDaily'
import { useParticipants } from './useParticipants'
import { usePaginatedParticipants } from './usePaginatedParticipants'
import { memoize } from 'lodash'
import { onceSet } from '../onceSet'
import { useAppMessage } from './useAppMessage'
import { useStorageSettings } from './useStorageSettings'
import { useDevices } from './useDevices'
import { useErrors } from './useErrors'
import * as Sentry from '@sentry/browser'

type JoinOptions = {
  userName: string
  userId: string
  isHost: boolean | undefined
}

type Threshold = DailyNetworkStats['threshold']
type CpuLoadState = DailyCpuLoadStats['cpuLoadState']
type LocalParticipant = Participant | undefined
type SendSettingsMaxQuality = DailyVideoSendSettings['maxQuality']

export const useLocalParticipant = memoize((roomName: string) => {
  const { callObj, removeVideoAndAudioProcessors } = useDaily(roomName)
  const { participantsAndLocal, participants } = useParticipants(roomName)
  const threshold = ref<Threshold>()
  const cpuState = ref<CpuLoadState>()
  const settings = useStorageSettings()
  const highQualityUserId = ref<string>()
  const toast = useToast()
  const isUserJoined = ref(false)

  const local = computed<LocalParticipant>(() =>
    participantsAndLocal.value.find((p) => p.local),
  )

  const startCall = async () => {
    await callObj.value?.startCamera()
    await callObj.value?.load()
  }

  const joinCall = async ({ userName, userId, isHost }: JoinOptions) => {
    onceSet(callObj, async (callObj) => {
      const options: DailyCallOptions = {
        userName: toConcatedName(userName, userId),
        receiveSettings: {
          base: { video: { layer: 0 } },
        },
      }
      if (isHost) {
        options.token = await signHost(roomName)
      }
      await callObj.join(options)
      isUserJoined.value = true
      callObj.setLocalAudio(settings.value.audio ?? callObj.localAudio())
      callObj.setLocalVideo(settings.value.video ?? callObj.localVideo())
      const workerId = await callObj.setNetworkTopology({
        topology: 'sfu',
      })
      console.log(`useLocal joinCall: sfu: ${JSON.stringify(workerId)}`)
    })
  }

  const toggleAudio = () => {
    const newValue = !local.value?.audio
    callObj.value?.setLocalAudio(newValue)
    settings.value.audio = newValue
  }

  const toggleVideo = () => {
    const newValue = !local.value?.video
    callObj.value?.setLocalVideo(newValue)
    settings.value.video = newValue
  }

  const toggleScreenShare = () => {
    if (local.value?.screen) {
      callObj.value?.stopScreenShare()
    } else {
      callObj.value?.startScreenShare()
    }
  }

  const leaveCall = async () => {
    if (!callObj.value) return

    if (local.value?.screen) {
      callObj.value.stopScreenShare()
    }

    await callObj.value.leave()
    await callObj.value.destroy()
    callObj.value = undefined

    const cachedFns = [
      useLocalParticipant,
      useDaily,
      useParticipants,
      usePaginatedParticipants,
      useAppMessage,
      useDevices,
      useErrors,
    ]

    cachedFns.forEach((fn) => {
      fn.cache.delete(roomName)
    })
  }

  const receiveSettings = computed<DailyReceiveSettingsUpdates>(() => {
    let overrideSettings: DailyReceiveSettingsUpdates | undefined

    const hasGoodPerformance =
      threshold.value === 'good' && cpuState.value === 'low'

    if (highQualityUserId.value && hasGoodPerformance) {
      overrideSettings = {
        [highQualityUserId.value]: { video: { layer: 2 } },
      }
    }

    return {
      '*': { video: { layer: hasGoodPerformance ? 1 : 0 } },
      ...overrideSettings,
    }
  })

  const sendSettingsMaxQuality = computed<SendSettingsMaxQuality>(() =>
    local.value?.user_id === highQualityUserId.value &&
    threshold.value === 'good' &&
    cpuState.value === 'low'
      ? 'high'
      : 'medium',
  )

  const setHighQualityUserId = (userId: string | undefined) => {
    highQualityUserId.value = userId
  }

  const muteAll = () => {
    if (!callObj.value) return

    const updatedParticipantList: Record<
      string,
      Pick<DailyParticipantUpdateOptions, 'setAudio'>
    > = {}
    for (const participant of participants.value) {
      updatedParticipantList[participant.session_id] = { setAudio: false }
    }

    callObj.value.updateParticipants(updatedParticipantList)
  }

  const toggleShareScreenAudioOnly = () => {
    if (!callObj.value) return

    const curShareScreenAudioOnly = local.value?.userData?.shareScreenAudioOnly

    callObj.value.setUserData({
      shareScreenAudioOnly: !curShareScreenAudioOnly,
    })
  }

  watch(
    // Daily throws an error if try to updateReceiveSettings before local has joined
    [() => (isUserJoined.value ? receiveSettings.value : undefined), callObj],
    async ([receiveSettings, callObj]) => {
      if (receiveSettings && callObj) {
        const currentReceiveSettings = await callObj.getReceiveSettings()

        // updateReceiveSettings only adds new ones, we need to reset old receiveSettings with the update
        for (const key in currentReceiveSettings) {
          currentReceiveSettings[key].video = { layer: 0 }
        }

        await callObj.updateReceiveSettings({
          ...currentReceiveSettings,
          ...receiveSettings,
        })
      }
    },
  )

  watch(
    [sendSettingsMaxQuality, callObj],
    async ([maxQuality, callObj]) => {
      if (callObj && maxQuality) {
        await callObj.updateSendSettings({
          video: {
            maxQuality,
          },
        })
      }
    },
    {
      immediate: true,
    },
  )

  onceSet(callObj, async (callObj) => {
    callObj.on('network-quality-change', (e) => {
      threshold.value = e?.threshold
    })

    callObj.on('cpu-load-change', (e) => {
      cpuState.value = e?.cpuLoadState
    })

    const networkStats = await callObj.getNetworkStats()
    threshold.value = networkStats.threshold

    const cpuStats = await callObj.getCpuLoadStats()
    cpuState.value = cpuStats.cpuLoadState
  })

  onceSet(local, (local) => {
    Sentry.setContext('daily', {
      session_id: local.session_id,
      owner: local.owner,
      user_id: local.user_id,
      joined_at: local.joined_at,
      user_name: local.user_name,
    })
  })

  watch(threshold, (newThreshold, prevThreshold) => {
    if (newThreshold === 'very-low' && local.value?.video) {
      callObj.value?.setLocalVideo(false)
    }

    if (newThreshold === 'low' || newThreshold === 'very-low') {
      toast.clear()
      toast.warning(
        `Your internet connection is ${newThreshold}! You may exprience issues with video or audio`,
      )
    } else if (
      newThreshold === 'good' &&
      (prevThreshold === 'low' || prevThreshold === 'very-low')
    ) {
      toast.clear()
      toast.success('Your internet connection was restored!')
    }
  })

  onceSet(
    () => cpuState.value === 'high',
    async () => {
      await removeVideoAndAudioProcessors()
      toast.clear()
      toast.warning(
        `Your CPU is high! You may exprience issues with video or audio`,
      )

      Sentry.withScope(async (scope) => {
        scope.setLevel('warning')
        const cpuStats = await callObj.value?.getCpuLoadStats()
        console.log(
          `cpu: ${JSON.stringify({
            cpuLoadState: 'high',
            cpuLoadStateReason: cpuStats?.cpuLoadStateReason,
            stats: cpuStats?.stats,
          })}`,
        )
        Sentry.captureMessage('CPU is high')
      })
    },
  )

  return {
    local,
    startCall,
    leaveCall,
    joinCall,
    toggleAudio,
    toggleVideo,
    toggleScreenShare,
    setHighQualityUserId,
    muteAll,
    toggleShareScreenAudioOnly,
  }
})
