import { DailyDeviceInfos } from '@daily-co/daily-js'
import { memoize, uniqBy } from 'lodash'
import { computed, readonly, shallowRef } from 'vue'
import { onceSet } from '../onceSet'
import { useDaily } from './useDaily'
import { useStorageSettings } from './useStorageSettings'

export const useDevices = memoize((roomName: string) => {
  const { callObj } = useDaily(roomName)
  const mediaDevices = shallowRef<MediaDeviceInfo[]>()
  const settings = useStorageSettings()

  const camsDevices = computed(
    () =>
      mediaDevices.value?.filter(
        ({ kind, deviceId }) => kind === 'videoinput' && deviceId !== '',
      ),
  )
  const micsDevices = computed(
    () =>
      mediaDevices.value?.filter(
        ({ kind, deviceId }) => kind === 'audioinput' && deviceId !== '',
      ),
  )

  const updateSettingDeviceAudioId = (devicesInfo: DailyDeviceInfos) => {
    settings.value.audioDeviceId = (devicesInfo.mic as MediaDeviceInfo).deviceId
  }

  const updateSettingDeviceVideoId = (devicesInfo: DailyDeviceInfos) => {
    settings.value.videoDeviceId = (
      devicesInfo.camera as MediaDeviceInfo
    ).deviceId
  }

  const updateDevices = async () => {
    if (!callObj.value) return
    const { devices } = await callObj.value.enumerateDevices()
    mediaDevices.value = uniqBy(devices, 'deviceId')
    const curDevicesInfo = await callObj.value.getInputDevices()
    updateSettingDeviceAudioId(curDevicesInfo)
    updateSettingDeviceVideoId(curDevicesInfo)
  }

  const updateAudioDevice = async (deviceId: string) => {
    if (!callObj.value) return
    const devicesInfo = await callObj.value.setInputDevicesAsync({
      audioDeviceId: deviceId,
    })
    updateSettingDeviceAudioId(devicesInfo)
  }

  const updateVideoDevice = async (deviceId: string) => {
    if (!callObj.value) return
    const devicesInfo = await callObj.value.setInputDevicesAsync({
      videoDeviceId: deviceId,
    })
    updateSettingDeviceVideoId(devicesInfo)
  }

  onceSet(callObj, async (callObj) => {
    callObj.on('available-devices-updated', updateDevices)
    await updateDevices()
  })

  return {
    camsDevices,
    micsDevices,
    updateAudioDevice,
    updateVideoDevice,
    settings: readonly(settings),
  }
})
