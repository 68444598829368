import { defineComponent, PropType } from 'vue'
import * as styles from '@/components/molecules/tag/Tag.css'
import { Dot, DotColors } from '@/components/atoms/dot/Dot'

export const Tag = defineComponent({
  name: 'Tag',
  props: {
    color: {
      type: String as PropType<DotColors>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '7rem',
    },
  },
  setup(props) {
    return () => (
      <div
        class={styles.root}
        style={{
          maxWidth: props.maxWidth,
        }}
      >
        <Dot class={styles.dot} color={props.color} />
        <div class={styles.title}>{props.title}</div>
      </div>
    )
  },
})
