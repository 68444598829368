import { defineComponent, PropType, Transition } from 'vue'
import * as styles from '@/components/atoms/fade/Fade.css'

export const Fade = defineComponent({
  name: 'Fade',
  props: {
    appear: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<'default' | 'out-in' | 'in-out'>,
      default: 'default',
    },
    delay: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    return () => (
      <Transition
        mode={props.mode}
        appear={props.appear}
        enter-active-class={
          props.delay ? styles.fadeActiveDelay : styles.fadeActive
        }
        leave-active-class={
          props.delay ? styles.fadeActiveDelay : styles.fadeActive
        }
        enter-from-class={styles.initialOpacity}
        leave-to-class={styles.initialOpacity}
      >
        {slots.default?.()}
      </Transition>
    )
  },
})
