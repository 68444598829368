import { defineComponent } from 'vue'
import * as styles from '@/modules/join/LocalTile.css'
import { LocalWaitingTile } from '@/modules/join/LocalWaitingTile'
import { useLocalParticipant } from '@/utils/streaming/useLocalParticipant'
import { MediaDevicesControls } from '@/modules/media/MediaDevicesControls'

export const LocalTile = defineComponent({
  name: 'LocalTile',
  props: {
    roomName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { local } = useLocalParticipant(props.roomName)

    return () => (
      <>
        {local.value && <LocalWaitingTile player={local.value} />}
        <div class={styles.waitingBoxControls}>
          <MediaDevicesControls roomName={props.roomName} />
        </div>
      </>
    )
  },
})
