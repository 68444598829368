import { onMounted, onUpdated, ref, shallowRef } from 'vue'

export const useMediaStream = (
  getMediaStreamData: () => {
    persistentTrack: MediaStreamTrack | undefined
    shouldSetMediaStream: boolean
  },
) => {
  const source = shallowRef<MediaStream | null>(null)
  const oldTrackId = ref<string>()

  const handleStream = () => {
    const { persistentTrack, shouldSetMediaStream } = getMediaStreamData()

    if (shouldSetMediaStream) {
      if (!persistentTrack) return
      if (!source.value || oldTrackId.value !== persistentTrack.id) {
        source.value = new MediaStream([persistentTrack])
        oldTrackId.value = persistentTrack.id
      }
    } else {
      source.value = null
    }
  }

  onMounted(handleStream)
  onUpdated(handleStream)

  return source
}
