import { defineComponent } from 'vue'
import * as styles from '@/components/atoms/asset/Asset.css'

export const Asset = defineComponent({
  name: 'Asset',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    invert: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    pointerNone: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <img
        class={[
          props.full && styles.full,
          props.invert && styles.invert,
          props.pointerNone && styles.pointerNone,
        ]}
        src={props.src}
        alt={props.alt}
      />
    )
  },
})
