import { useThrottleFn } from '@vueuse/core'
import { Channel, Members } from 'pusher-js'
import { Member, MembersInfo } from 'types'
import { ref, shallowRef } from 'vue'
import { ConnectionState, usePusher } from './usePusher'

export const useChannelState = (
  channel: Channel,
  updateFn: () => Promise<void>,
) => {
  const { pusher } = usePusher()
  const channelMembers = shallowRef<MembersInfo>({})
  const unavailable = ref(false)
  const throttledUpdate = useThrottleFn(updateFn, 250)

  channel.bind('pusher:subscription_succeeded', ({ me, members }: Members) => {
    const { id, info } = me as Member
    channelMembers.value = {
      ...(members as MembersInfo),
      [id]: info,
    }
  })

  channel.bind('pusher:member_added', ({ id, info }: Member) => {
    channelMembers.value = {
      ...channelMembers.value,
      [id]: info,
    }
  })

  channel.bind('pusher:member_removed', ({ id }: Member) => {
    const value = { ...channelMembers.value }
    delete value[id]
    channelMembers.value = value
  })

  pusher.connection.bind(
    'state_change',
    ({ current }: { current: ConnectionState }) => {
      unavailable.value = current === ConnectionState.unavailable
      if (current !== ConnectionState.connected) return
      throttledUpdate()
    },
  )

  channel.bind('refetch', throttledUpdate)

  return { channelMembers, unavailable }
}
