import { usePusher, PusherSubscriptionError } from '@/utils/usePusher'
import * as Sentry from '@sentry/browser'

export const useChannel = (channelName: string) => {
  const { pusher } = usePusher()
  const channel = pusher.subscribe(channelName)

  channel.bind(
    'pusher:subscription_error',
    ({ status: statusCode }: PusherSubscriptionError) => {
      const err = new Error('Pusher error')
      Sentry.captureException(Object.assign(err, { statusCode }))
      console.error(err)
    },
  )

  const unsubscribe = () => {
    if (channel.subscribed) {
      channel.unsubscribe()
    } else if (channel.subscriptionPending) {
      channel.cancelSubscription()
    }
  }

  return { channel, unsubscribe }
}
