import { defineComponent } from 'vue'
import * as styles from '@/components/atoms/backdrop/Backdrop.css'
import type { VariantPropType } from '@/utils/css'

export type BackdropPropType = VariantPropType<typeof styles.backdrop, 'type'>

export const Backdrop = defineComponent({
  name: 'Backdrop',
  props: {
    type: {
      type: String as BackdropPropType,
      default: 'dark',
    },
  },
  setup(props) {
    return () => (
      <div
        class={styles.backdrop({
          type: props.type,
        })}
      />
    )
  },
})
