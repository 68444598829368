import { defineComponent } from 'vue'
import { ConfirmModal } from '@/components/molecules/confirmModal/ConfirmModal'
import { useErrors } from '@/utils/streaming/useErrors'
import * as styles from '@/modules/media/MediaErrorModal.css'
import { endsWith } from 'lodash'

export const MediaNonFatalErrorModal = defineComponent({
  name: 'MediaNonFatalErrorModal',
  props: {
    roomName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { nonFatalError } = useErrors(props.roomName)

    const onConfirm = () => {
      nonFatalError.value = undefined
    }

    return () => {
      if (!nonFatalError.value) return null
      const type = nonFatalError.value.type

      if (type === 'screen-share-error') {
        const errorMsg = nonFatalError.value.errorMsg
        const blockedByOs = endsWith(errorMsg, 'blocked-by-os')

        if (blockedByOs) {
          return (
            <ConfirmModal
              title={`Can't share your screen`}
              v-slots={{
                content: () => (
                  <>
                    <div class={styles.textSize}>
                      Your browser needs permissions to share your screen.
                    </div>
                    <ol class={styles.guideListOl}>
                      <li class={styles.textSize}>
                        Go to{' '}
                        <a
                          class={[styles.textSize, styles.link]}
                          href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"
                        >
                          Screen Recording Preferneces
                        </a>
                        .
                      </li>
                      <li class={styles.textSize}>
                        On the bottom left, click the lock icon to make changes.
                      </li>
                      <li class={styles.textSize}>
                        In the window, enter in your password and then click
                        unlock.
                      </li>
                      <li class={styles.textSize}>
                        On the right, check the Google Chrome or Firefox box.
                      </li>
                      <li class={styles.textSize}>
                        You might need to restart your browser.
                      </li>
                    </ol>
                  </>
                ),
              }}
              onConfirm={onConfirm}
            />
          )
        }
      }

      return null
    }
  },
})
