import { defineComponent } from 'vue'
import * as styles from '@/components/atoms/input/Input.css'
import { trim } from 'lodash'
import { Asset } from '@/components/atoms/asset/Asset'

export const Input = defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    iconSrc: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const onInput = (e: Event) =>
      emit('input', trim((e.target as HTMLInputElement).value))

    return () => (
      <label class={[styles.root, !props.readonly && styles.interactiveRoot]}>
        {props.iconSrc && (
          <Asset
            class={[
              styles.asset,
              props.readonly ? styles.readonlyAsset : styles.interactiveAsset,
            ]}
            src={props.iconSrc}
          />
        )}
        <input
          type={props.type}
          class={[
            styles.input,
            props.textCenter
              ? styles.textCenter
              : !props.iconSrc
              ? styles.inputMarginLeft
              : undefined,
          ]}
          value={props.value}
          placeholder={props.placeholder}
          readonly={props.readonly}
          onInput={onInput}
          autocomplete={props.autocomplete ?? 'off'}
          name={props.name}
        />
      </label>
    )
  },
})
