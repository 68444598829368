import {
  defineComponent,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
} from 'vue'
import * as styles from '@/components/molecules/videoTile/VideoTile.css'
import { PlayerPlaceholder } from '@/components/atoms/playerPlaceholder/PlayerPlaceholder'
import { AudioSource } from '@/components/atoms/audioSource/AudioSource'

export const VideoTile = defineComponent({
  name: 'VideoTile',
  // vue props don't support mediaTrack type for audioSource and videoSource
  /* eslint-disable vue/require-prop-types */
  props: [
    'audioSource',
    'videoSource',
    'width',
    'height',
    'maxWidth',
    'maxHeight',
    'flip',
    'isLargePlaceholder',
    'isLargeBorder',
    'noBackground',
    'objectFit',
  ],
  setup(props) {
    const instance = getCurrentInstance()

    onMounted(() => {
      watch(
        () => props.videoSource,
        () => {
          nextTick(() => {
            const video = instance?.refs.video as HTMLVideoElement | undefined
            if (video) {
              video.srcObject = props.videoSource
            }
          })
        },
        { immediate: true },
      )
    })

    return () => (
      <div class={styles.root}>
        {props.audioSource && (
          <AudioSource getAudioSource={() => props.audioSource} />
        )}
        <PlayerPlaceholder
          class={styles.placeholder}
          isLarge={props.isLargePlaceholder}
          noBackground={props.noBackground}
        />
        {props.videoSource && (
          <video
            class={[
              styles.video,
              props.isLargeBorder
                ? styles.videoLargeBorder
                : styles.videoSmallBorder,
              props.flip && styles.flip,
            ]}
            style={{
              width: props.width ?? '100%',
              height: props.height ?? '100%',
              maxWidth: props.maxWidth ?? 'unset',
              maxHeight: props.maxHeight ?? 'unset',
              objectFit: props.objectFit ?? 'cover',
            }}
            autoplay
            muted
            playsinline
            ref="video"
          />
        )}
      </div>
    )
  },
})
