import {
  buildFileUrl,
  parseFileAssetId,
  SanityImageSource,
} from '@sanity/asset-utils'
import imageUrlBuilder from '@sanity/image-url'
import { sanityConfig } from '@/utils/sanityConfig'

export function getImageUrl(image: SanityImageSource) {
  const dpr = Number((window.devicePixelRatio ?? 2).toFixed(1))
  const builder = imageUrlBuilder(sanityConfig).image(image).dpr(dpr)
  return builder.auto('format')
}

export function getFileUrl(fileRef: string) {
  return buildFileUrl(parseFileAssetId(fileRef), sanityConfig)
}
