import { defineComponent, PropType } from 'vue'
import { useVideo } from '@/utils/streaming/useVideo'
import { Participant } from '@/utils/streaming/useDaily'
import { VideoTile } from '@/components/molecules/videoTile/VideoTile'

export const LocalWaitingTile = defineComponent({
  name: 'LocalWaitingTile',
  props: {
    player: {
      type: Object as PropType<Participant>,
      required: true,
    },
  },
  setup(props) {
    const videoSource = useVideo(() => props.player)

    return () => (
      <VideoTile
        videoSource={videoSource.value}
        flip
        isLargePlaceholder
        isLargeBorder
        noBackground
      />
    )
  },
})
