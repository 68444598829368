import { defineComponent, PropType } from 'vue'
import { colors } from '@/styles/player.css'
import * as styles from '@/components/atoms/dot/Dot.css'

export type DotColors = keyof typeof colors | 'white'

export const Dot = defineComponent({
  name: 'Dot',
  props: {
    color: {
      type: String as PropType<DotColors>,
      required: true,
    },
  },
  setup(props) {
    return () => {
      const background = styles.backgroundVariants[props.color]
      const boxShadow = styles.boxShadowVariants[props.color]

      return (
        <div class={styles.root}>
          <div class={[styles.innerShadow, background]}></div>
          <div class={[styles.innerDot, background, boxShadow]}></div>
        </div>
      )
    }
  },
})
