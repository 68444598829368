import { defineComponent } from 'vue'
import { ConfirmModal } from '@/components/molecules/confirmModal/ConfirmModal'
import * as styles from '@/modules/media/MediaErrorModal.css'
import { useErrors } from '@/utils/streaming/useErrors'

export const MediaCameraErrorModal = defineComponent({
  name: 'MediaCameraErrorModal',
  props: {
    roomName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { cameraError } = useErrors(props.roomName)

    const onConfirm = () => {
      cameraError.value = undefined
    }

    return () => {
      if (!cameraError.value) return null

      const errorMsg = cameraError.value.errorMsg
      const errorType = cameraError.value.error.type

      if (errorMsg.errorMsg === 'not allowed') {
        if (errorType === 'permissions') {
          return (
            <ConfirmModal
              title="Unblock Your Camera And Mic"
              v-slots={{
                content: () => (
                  <>
                    <div class={styles.textSize}>
                      Your browser needs camera and microphone access.
                    </div>
                    <div class={styles.guideList}>
                      <div class={styles.textSize}>
                        1. Click the camera icon in your browser's adress bar
                      </div>
                      <div class={styles.textSize}>
                        2. Select “Always allow”, then click Done
                      </div>
                      <div class={styles.textSize}>3. Refresh the page</div>
                    </div>
                  </>
                ),
              }}
              onConfirm={onConfirm}
            />
          )
        }

        const device =
          errorType === 'cam-mic-in-use'
            ? 'Cam & Mic'
            : errorType === 'cam-in-use'
            ? 'Camera'
            : 'Microphone'

        return (
          <ConfirmModal
            title={`Another App Is Using Your ${device}`}
            v-slots={{
              content: () =>
                `Select a different ${device} or quit that app and reload this game to use your ${device} in this game`,
            }}
            onConfirm={onConfirm}
          />
        )
      }

      if (errorType === 'not-found') {
        const missingMedia = cameraError.value.error.missingMedia

        const device =
          missingMedia.length === 1
            ? missingMedia.includes('video')
              ? 'Camera'
              : 'Microphone'
            : 'Cam & Mic'

        return (
          <ConfirmModal
            title={`No ${device} Detected`}
            confirmText="Try Again"
            v-slots={{
              content: () =>
                `Make sure your ${device} ${
                  device === 'Cam & Mic' ? 'are' : 'is'
                } connected and try again`,
            }}
            onConfirm={onConfirm}
          />
        )
      }

      return null
    }
  },
})
