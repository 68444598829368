import { memoize } from 'lodash'
import { useDaily } from './useDaily'
import { onceSet } from '../onceSet'
import { shallowRef } from 'vue'
import { v5 as uuid, v4 } from 'uuid'

export type Message = {
  id: string
  text: string
  userId: string
  userName: string
  isHost: boolean
}

export const useAppMessage = memoize((roomName: string) => {
  const { callObj } = useDaily(roomName)
  const messages = shallowRef<Message[]>([])

  const updateMessages = (data: Message) => {
    if (messages.value.some((m) => m.id === data.id)) return
    messages.value = messages.value.concat(data)
  }

  const sendAppMessage = ({
    userId,
    text,
    userName,
    isHost,
  }: Omit<Message, 'id'>) => {
    if (!callObj.value) return
    const data: Message = {
      id: uuid(v4(), userId),
      text,
      userId,
      userName,
      isHost,
    }
    callObj.value.sendAppMessage(data, '*')
    updateMessages(data)
  }

  // no need to off events, being handle in useLocalParticipant leaveCall
  onceSet(callObj, (callObj) => {
    callObj.on('app-message', (e) => {
      e?.data && updateMessages(e.data)
    })
  })

  return {
    sendAppMessage,
    messages,
  }
})
