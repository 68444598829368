import { defineComponent } from 'vue'
import { MediaCameraErrorModal } from './MediaCameraErrorModal'
import { MediaNonFatalErrorModal } from './MediaNonFatalErrorModal'
import { MediaWebglUnsupportedModal } from './MediaWebglUnsupportedModal'

export const MediaErrorModal = defineComponent({
  name: 'MediaErrorModal',
  props: {
    roomName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return () => (
      <>
        <MediaCameraErrorModal roomName={props.roomName} />
        <MediaNonFatalErrorModal roomName={props.roomName} />
        <MediaWebglUnsupportedModal roomName={props.roomName} />
      </>
    )
  },
})
